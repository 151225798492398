import React from 'react'
import { useState } from 'react';
import { Popup } from 'react-popupify'
import { getSubscriberInfo } from '../../axios/study';

// Дані користувача
const initialData = {
    username: "",
};

const CustomPopupLogin = ({open, setIsOpen, сurrentEvent}) => {

const [logData, setLogData] = useState(initialData);
const [loader, setLoader] = useState(false);
const [alert, setAlert] = useState(false);

const handleChange = (e) => {
    const { name, value } = e.target;
    setLogData({
      ...logData,
      [name]: value
    });
  };

 function submitLog (event) {
     event.preventDefault();
     setLoader(true);
     setAlert(false);

     const form = event.currentTarget;
     if (form.checkValidity() === false) {
       event.preventDefault();
       event.stopPropagation();
     } else {

      getSubscriberInfo(logData.username, сurrentEvent).then((response) => {
        const [data] = response['hydra:member'];

         if (data !== undefined) {
           // Внести дані залогіненого користувача в localStorage для подальшого використання
           localStorage.setItem('user_id', data.id);
           localStorage.setItem('events', JSON.stringify(data?.events || ''));
           localStorage.setItem('location', JSON.stringify(data?.location || ''));
           localStorage.setItem('specialty', JSON.stringify(data?.specialty || ''));
           localStorage.setItem('specialties', JSON.stringify(data?.specialties || ''));
           setIsOpen(false);
         } else {
          setAlert(true);
         }

       }).catch(() => {
         setLoader(false);
         setAlert(true);

       }).finally(() => {
        setLoader(false);
        // очистити алерт через 2 сек
          setTimeout(()=>{
            setAlert(false);
          }, 2000)
       })
     };
   }

  return (
    <Popup
    popupId="loginPopup"
    animation="zoom"
    open={open}
    closeOnEscape={true}
    closeOnOutsideClick={true}
    closeButton={true}
    onClose = {()=> {setIsOpen(false)}}
  >
    <h4>
      Ідентифікація учасника події
    </h4>
    <p>Для того щоб ми змогли підібрати для вас відповідні матеріали вам потрібно ідентифікуватись або зареєструватись, 
        якщо ви цього ще не робили для події:<b>Навчання працівників ремонтного періоду 2024</b></p>
    <form action="submit" onSubmit={submitLog}>
    <div className="form__group field">
        <input value={logData.name} onChange={handleChange} type="input" className="form__field" placeholder="П.І.Б. - логін" name="username" id='username' required />
        <label htmlFor="username" className="form__label">П.І.Б. - логін</label>
    </div>
    {alert && <p className='form-alert'>Невірний П.І.Б. - логін або учасника не зареєстровано</p>}
    <button className="entrance__btn form" type='submit'>
        <div className="study__top-reg--text entrance__text">
          Увійти {loader && <div className="loader sm"></div>}
        </div>
    </button>
    </form>
  </Popup>
  )
}

export default CustomPopupLogin