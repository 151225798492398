// contactsLocation це json перекладів на інші мови передаєм стрінгу

const dataContacts = [
  {
    // title: ["Радехівський", "підрозділ"],
    title: ["contactsLocation.radekhiv.title1", "contactsLocation.radekhiv.title2"],
    location: {
      // text: "80250, Україна, Львівська обл., Червоноградський р-н, с. Павлів, пр-т Юності, 39",
      text: "contactsLocation.radekhiv.location",

      pathAPI:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d535967.2631529623!2d24.18912823463525!3d50.17396587619647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47253a66d37cb01b%3A0xcae3e5b13dfe8bc!2z0L_RgNC-0YHQvy4g0K7QvdC-0YHRgtGWLCDQn9Cw0LLQu9GW0LIsINCb0YzQstGW0LLRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgODAyNTA!5e0!3m2!1suk!2sua!4v1695874667420!5m2!1suk!2sua",
    },

    email: "office.p@radsugar.com.ua",
    phone: ["+38 032 554-11-10", "+380 50 911-10-10"],
  },

  {
    // title: ["Чортківський", "підрозділ"],
    title: ["contactsLocation.chortkiv.title1", "contactsLocation.chortkiv.title2"],
    location: {
      // text: "48523, Україна, Тернопільська обл., Чортківський р-н, смт. Заводське, вул.Івана Франка, 1",
      text: "contactsLocation.chortkiv.location",

      pathAPI:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d713137.7943189173!2d25.222786116494106!3d49.017828257438765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47317459230616e5%3A0xe80605f65023de08!2z0J3QvtCy0LAg0L_QvtGI0YLQsCDihJYx!5e0!3m2!1suk!2sua!4v1695874817459!5m2!1suk!2sua",
    },

    email: "office.h@radsugar.com.ua",
    phone: ["+38 032 554-11-10", "+380 50 911-10-10"],
  },

  {
    // title: ["Збаразький", "підрозділ"],
    title: ["contactsLocation.zbarazh.title1", "contactsLocation.zbarazh.title2"],
    location: {
      // text: "47301, Україна, Тернопільська обл., Тернопільський р-н м. Збараж, Майдан Кармелюка, 1",
      text: "contactsLocation.zbarazh.location",

      pathAPI:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d282057.91150831163!2d25.501912863813672!3d49.627298594227334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47302c68631df7c1%3A0x47bda374489036bd!2z0JfQsdCw0YDQsNC2LCDQotC10YDQvdC-0L_RltC70YzRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgNDczMDI!5e0!3m2!1suk!2sua!4v1695874909569!5m2!1suk!2sua",
    },

    email: "tutska.t@radsugar.com.ua",
    phone: ["+38 032 554-11-10", "+380 50 911-10-10"],
  },

  {
    // title: ["Козівський", "підрозділ"],
    title: ["contactsLocation.kozova.title1", "contactsLocation.kozova.title2"],
    location: {
      // text: "47602, Україна, Тернопільська обл., Тернопільський р-н, смт. Козова, вул. Заводська 1",
      text: "contactsLocation.kozova.location",

      pathAPI:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29565.859613244178!2d25.1506356106362!3d49.423531045126694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4730430de9d91961%3A0x47b513b65a39e3b9!2z0LLRg9C70LjRhtGPINCX0LDQstC-0LTRgdGM0LrQsCwg0JrQvtC30L7QstCwLCDQotC10YDQvdC-0L_RltC70YzRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgNDc2MDI!5e0!3m2!1suk!2sua!4v1695874962909!5m2!1suk!2sua" ,
    },

    email: "tutska.t@radsugar.com.ua",
    phone: ["+38 032 554-11-10", "+380 50 911-10-10"],
  },

  {
    // title: ["Хоростківський", "підрозділ"],
    title: ["contactsLocation.khorostkiv.title1", "contactsLocation.khorostkiv.title2"],
    location: {
      // text: "48244, Україна, Тернопільська обл., Чортківський р-н, м. Хоростків, вул. Заводська 1",

      text: "contactsLocation.khorostkiv.location",

      pathAPI:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83409.02811019566!2d25.761620905750853!3d49.209434603707386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4731a329e5fe0e1b%3A0xcc80c4a1eb75ce5c!2z0KXQvtGA0L7RgdGC0LrRltCyLCDQotC10YDQvdC-0L_RltC70YzRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgNDgyNDA!5e0!3m2!1suk!2sua!4v1695875006307!5m2!1suk!2sua",
    },

    email: "office.h@radsugar.com.ua",
    phone: ["+38 032 554-11-10", "+380 50 911-10-10"],
  },
  
  {
    // title: ["Гнідавський", "підрозділ"],
    title: ["contactsLocation.hnidava.title1", "contactsLocation.hnidava.title2"],
    location: {
      // text: "43022, Україна, Волинська обл., Луцький р-н, с. Рованці, вул. Промислова 3",
      text: "contactsLocation.hnidava.location",

      pathAPI:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20203.732069571633!2d25.32768872608358!3d50.72983588536768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47259a2300000001%3A0xcf66941d8f34acfe!2z0JPQndCG0JTQkNCS0KHQrNCa0JjQmSDQptCj0JrQoNCe0JLQmNCZINCX0JDQktCe0JQ!5e0!3m2!1suk!2sua!4v1698059798567!5m2!1suk!2sua",
    },

    email: "office.g@radsugar.com.ua",
    phone: ["+38 032 554-11-10", "+380 50 911-10-10"],
  },
];

const allDataContacns = {
  dataContacts,
};

export default allDataContacns;



const contacts = [{

}]