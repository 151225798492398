// import a from '../../../media/images/engineer/Баран_Лідія.jpg'

const dataEngineers = [
  {
    id: '1',
    imgUrl: "media/images/engineer/Фуярчук_Любов.jpg",
    firstName: "Любов",
    lastName: "Фуярчук",
    phone: "+38 050 384-20-53",
    email: "fuyarchuk.l@radsugar.com.ua",
  },

  {
    id: '2',
    imgUrl: "media/images/engineer/Домитраш_Петро.jpg",
    firstName: "Петро",
    lastName: "Домитраш",
    phone: "+38 050 384-19-51",
    email: "domytrash.p.l@radsugar.com.ua",
  },

  {
    id: '3',
    imgUrl: "media/images/engineer/Баран_Лідія.jpg",
    firstName: "Лідія",
    lastName: "Баран",
    phone: "+38 050 580-03-89",
    email: "baran.l@radsugar.com.ua",
  },

  {
    id: '4',
    imgUrl: "media/images/engineer/Бісовський_Михайло.jpg",
    firstName: "Михайло",
    lastName: "Бісовський",
    phone: "+38 050 361-25-13",
    email: "bisovskyi.m@radsugar.com.ua",
  },

  {
    id: '5',
    imgUrl: "media/images/engineer/Лукянець_Ірина.jpg",
    firstName: "Ірина",
    lastName: "Лукіянець",
    phone: "+38 050 449-07-31",
    email: "lukiyanets.i@radsugar.com.ua",
  },

  {
    id: '6',
    imgUrl: "media/images/engineer/Воронович_ Михайло.jpg",
    firstName: "Михайло",
    lastName: "Воронович",
    phone: "+38 050 303-15-68",
    email: "voronovych.m@radsugar.com.ua",
  },
];

const allDataEngineers = {
  dataEngineers,
};

export default allDataEngineers;
