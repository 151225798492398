import React, { useEffect, useRef, useState } from "react";
// import ReactPlayer from 'react-player';
import { Link, useLocation } from "react-router-dom";
import routes from "../routes";
import { HeadTop } from "./HeadTop";
import { Breadcrumbs } from "./Breadcrumps";
import Navbar2 from "./Navbar/Navbar2";
// import { ClientRoom } from './LogRegister/ClientRoom';
import { useTranslation } from "react-multi-lang";
import { getProductList } from "../axios/menu";

export const Header = ({ lang, setLang }) => {
  const location = useLocation();

  const t = useTranslation();

  const [productList, SetProductList] = useState([]);
  const [activeItem, setActiveItem] = useState(false);

  const menuItems = [
    {
      id: 1,
      title: "Клієнтам",
      path: "/clients",
      submenu: [
        // {
        //   href: "#",
        //   title: "Цукор",
        //   // submenu: productList
        //   href: "https://diamantsugar.inneti.net/",
        // },
        // {
        //   // href: "/clients/zhom",
        //   title: "Жом",
        //   href: "https://radocorm-diamant.inneti.net/compost",
        // },
        // {
        //   // href: "/clients/melyasa",
        //   title: "Меляса",
        //   href: "https://radocorm-diamant.inneti.net/molasses",
        // },
      ],
    },
    {
      id: 2,
      title: "Працівникам",
      path: "/workers",
      submenu: [
        {
          href: "/study",
          title: "Навчання",
        },
        {
          href: "/vacancies",
          title: "Вакансії",
        },
        {
          href: "/best-employees",
          title: "Кращі працівники",
        },
      ],
    },
    {
      id: 3,
      title: "Фермерам",
      path: "/fermers",
      submenu: [
        {
          href: "/seed",
          title: "Насіння",
        },
        {
          href: "/technology-beet",
          title: "Технологія вирощування",
          // submenu: [
          //   {
          //     href: "/power",
          //     title: "Система живлення",
          //   },
          // ]
        },
        {
          href: "/shipment",
          title: "Збір та відвантаження",
        },
        {
          href: "/pages/1",
          title: "Навчання та семінари",
        },
      ],
    },
    {
      id: 4,
      title: "Водіям",
      path: "/drivers",
      submenu: [
        {
          href: "/logistics",
          title: "Логістика",
        },
      ],
    },
    {
      id: 4,
      title: "Партнерам",
      path: "/partners",
      submenu: [],
    },
  ];

  const menu = useRef(null);
  const showBtn = useRef(null);

  const currentRoute = routes.filter((route) => {
    return location.pathname.includes(route.path);
  });
  const lastElement = currentRoute.slice(-1)[0];

  function showMenu() {
    showBtn.current.classList.add("active");
    menu.current.classList.add("active");

    const currentActive = menuItems.find((menu) => {
      return location.pathname === menu.path;
    });
    console.log(currentActive);
    setActiveItem(currentActive !== undefined ? currentActive : false);
  }

  function closeMenu() {
    showBtn.current.classList.remove("active");
    menu.current.classList.remove("active");
  }

  // useEffect(() => {
  //   let active = false;

  //   getProductList().then((response) => {
  //     const data = response["hydra:member"];

  //     // фільтруємо дані за вибраною мовою
  //     const filtredLang = data.filter((elements) => {
  //       return elements.language.key === lang;
  //     });

  //     const productData = filtredLang.map((item) => {
  //       return {
  //         href: "#",
  //         img:
  //           process.env.REACT_APP_SERVER_URL + "/media/" + item.slug + ".jpg",
  //         title: item.name,
  //         submenu: item.products.map((item) => {
  //           return {
  //             path: "/clients/sugar/" + (item.translation?.id || item.id), // взяти parent id з translation, для перекладених товарів
  //             title: item.name,
  //             img:
  //               process.env.REACT_APP_SERVER_URL +
  //               (item.media[0] !== undefined
  //                 ? item.media[0]?.contentUrl
  //                 : item.translation.media[0]?.contentUrl),
  //             weight:
  //               item?.attributeItems.find(
  //                 (elem) => elem.attribute.slug === "weight_" + lang
  //               )?.name ||
  //               item.translation?.attributeItems.find(
  //                 (elem) => elem.attribute.slug === "weight_" + lang
  //               )?.name,
  //           }; // якщо не знайдена вага забираєм вагу з перекладів + lang до вибраної мови
  //         }),
  //       };
  //     });

  //     if (!active) {
  //       SetProductList(productData);
  //     }
  //   }).catch(err => {
  //     console.log(err);
  //   });
    
  //   return () => {
  //     active = true;
  //   };
  // }, [lang]);

  const [showLogo, setShowLogo] = useState(false);
  const breadsRef = useRef(null);

  useEffect(() => {
    if (breadsRef.current) {
      if (breadsRef.current.previousElementSibling.classList.contains("main")) {
        setShowLogo(true);
      } else {
        setShowLogo(false);
      }
    }
  }, [location]);

  return (
    <>
      <header className="main">
        <div className="container">
          <button className="menu-mobile" ref={showBtn} onClick={showMenu}>
            <span></span>
          </button>
          <div className="main__wrapper" ref={menu}>
            <Link to={`/`} className="main__logo logo" onClick={closeMenu}>
              <img
                src="/media/images/logo.svg"
                alt="logo"
                className="logo__img"
              />
              <div className="logo__text">
                {t("logo").split(" ")[0]}
                <br />
                {t("logo").split(" ")[1]}
              </div>
            </Link>

            <Navbar2
              menuItems={menuItems}
              productList={productList}
              translate={t}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              closeMobile={closeMenu}
            />

            <div className="main__contact">
              <div className="main__contact--company">
                <Link to={`#`} className="contact-btn">
                  {t("header.company.name")}
                </Link>
                <div className="main__contact-drop">
                  <div className="main__contact-drop-inner">
                    <Link to={`/history`} onClick={closeMenu}>
                      {t("header.company.sub0")}
                    </Link>
                    <Link to={`/news`} onClick={closeMenu}>
                      {t("header.company.sub1")}
                    </Link>
                    <Link to={`/reports`} onClick={closeMenu}>
                      {t("header.company.sub2")}
                    </Link>
                    <Link to={`/social-activity`} onClick={closeMenu}>
                      {t("header.company.sub3")}
                    </Link>
                    <Link to={`/energy`} onClick={closeMenu}>
                      {t("header.company.sub5")}
                    </Link>
                    <a href={`/kodeks_etyky_ua.pdf`} target="_blank" rel="noreferrer" onClick={closeMenu}>
                      {t("header.company.sub4")}
                    </a>
                  </div>
                </div>
              </div>
              <Link
                to="/contacts"
                className="main__contact--contact"
                onClick={closeMenu}
              >
                {t("header.contacts")}
              </Link>
            </div>
            <div className="icons-inner">
              <div className="main__lang">
                <div className="current-lang">{lang}</div>
                <div className="lang-list">
                  <ul>
                    <li
                      onClick={() => {
                        setLang("ua");
                        closeMenu();
                      }}
                    >
                      ua
                    </li>
                    <li
                      onClick={() => {
                        setLang("en");
                        closeMenu();
                      }}
                    >
                      en
                    </li>
                    <li
                      onClick={() => {
                        setLang("dn");
                        closeMenu();
                      }}
                    >
                      dn
                    </li>
                  </ul>
                </div>
              </div>
              <div className="main__nav">
                <Link
                  to={`/question`}
                  onClick={closeMenu}
                  className="main__nav--ques"
                ></Link>

                <div className="main__contact--company">
                  <Link to={`/partners`} className="main__nav--social"></Link>
                  <div className="main__contact-drop cabinets">
                    <div className="main__contact-drop-inner">
                      <Link
                        to="http://opt.diamantsugar.com.ua/en-us/"
                        target="_blanck"
                      >
                        {t("header.cabinets.sub0")}
                      </Link>
                      <Link
                        to="https://www.diamantsugar.com.ua/ua/event-members/authentication/8"
                        target="_blanck"
                      >
                        {t("header.cabinets.sub1")}
                      </Link>
                      <Link
                        to="https://diamantsugar.com.ua/ua/user/login"
                        target="_blanck"
                      >
                        {t("header.cabinets.sub2")}
                      </Link>
                      <Link
                        to="https://diamantsugar.com.ua/itender/"
                        target="_blanck"
                      >
                        {t("header.cabinets.sub3")}
                      </Link>
                      <Link
                        to="https://pidriadnyk.diamantsugar.com.ua/"
                        target="_blanck"
                      >
                        {t("header.cabinets.sub4")}
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <Link to={`#`} className="main__nav--social"></Link> */}
              </div>
            </div>
            {/* <ClientRoom/> */}
            <div className="close-mobile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={closeMenu}
              >
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                  fill="white"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={closeMenu}
              >
                <path
                  d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM13.92 16.13H9C8.59 16.13 8.25 15.79 8.25 15.38C8.25 14.97 8.59 14.63 9 14.63H13.92C15.2 14.63 16.25 13.59 16.25 12.3C16.25 11.01 15.21 9.97 13.92 9.97H8.85L9.11 10.23C9.4 10.53 9.4 11 9.1 11.3C8.95 11.45 8.76 11.52 8.57 11.52C8.38 11.52 8.19 11.45 8.04 11.3L6.47 9.72C6.18 9.43 6.18 8.95 6.47 8.66L8.04 7.09C8.33 6.8 8.81 6.8 9.1 7.09C9.39 7.38 9.39 7.86 9.1 8.15L8.77 8.48H13.92C16.03 8.48 17.75 10.2 17.75 12.31C17.75 14.42 16.03 16.13 13.92 16.13Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          {/* <ClientRoom/> */}
        </div>
      </header>
      <HeadTop currentRoute={lastElement} showMenu={showMenu} lang={lang} />
      <div className="breadcrump-inner" ref={breadsRef}>
        <div className="container">
          {showLogo ? (
            <div className="breadcrump-inner__logo">
              <Link to={"https://diamant-sugar.com.ua/"}>
                <img
                  src="/img/diamfooter.svg"
                  alt="di"
                  width={63}
                  height={49}
                />
              </Link>
              <Link to={"https://radocorm.com.ua/"}>
                <img
                  src="/img/kormfooter.svg"
                  alt="korm"
                  width={89}
                  height={54}
                />
              </Link>
            </div>
          ) : (
            <Breadcrumbs routes={routes} />
          )}
        </div>
      </div>
    </>
  );
};
